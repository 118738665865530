@import "../_mixin.scss";

.event-carousel-container {
	@include fill-container;
	position: relative;
	grid-row: 2 / span 1;
	overflow: hidden;

	@media (max-width: 960px) {
		@include hide-scrollbar;
		overflow: hidden scroll;
	}
}

.carousel-slide {
	width: max-content;
	height: 98%;
	position: absolute;
	top: 8px;
	opacity: 0.7;
	transition: opacity 1s;
	z-index: 500;

	@media screen and (max-width: 960px) {
		height: 60%;
		opacity: 1;
	}
}

.slide-focused {
	opacity: 1;
}

.backdrop-mobile {
	@include fill-container;

	z-index: 1000;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
}
