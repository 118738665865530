@import "../../_mixin.scss";

.PGP-key-container {
	width: 100%;
	height: 100%;
	background-color: white;
}

.PGP-key {
	color: black;
	font-family: $monospace;

	padding: 8px;

	width: 547px;
	height: 680px;
	max-width: 547px;
	max-height: 680px;
	min-width: 547px;
	min-height: 680px;

	outline: none;
	border: none;
	resize: none;
}
