@import "../_mixin.scss";

.info-box {
	@include basic-card;
	margin: 20px;
	flex-direction: column;
	width: 100%;
	max-width: 800px;
	min-width: 300px;

	overflow-y: auto;
}

.info-box > .close-button {
	top: 4px;
	right: 16px;
}

.readme-header-container {
	position: relative;
	width: 100%;
	height: fit-content;
	margin: 40px auto 5px auto;

	text-align: center;
}
.readme-header {
	@include readme-header-text;
	@include fitty;
	padding: 0px 10px;

	height: fit-content;
	vertical-align: middle;
}

.readme-text {
	width: 100%;
	padding: 10px 40px;
	text-indent: 5%;

	p {
		@include readme-text;
		padding-bottom: 15px;
	}
	@media (max-width: 530px) {
		padding: 10px 30px;
	}
	@media (max-width: 400px) {
		padding: 10px 20px;
	}
}

.readme-text-link {
	@include link($color: $contrast-color);
	word-break: keep-all;
	text-decoration-thickness: 0.5px;
}

.readme-contact-link {
	@include link($contrast-color);
}
