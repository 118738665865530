@import "../../_mixin.scss";

#chronology {
	@include fill-container;
}

.landing-modal-container {
	@include fill-container;
	@include flex-center;
	align-items: baseline;

	position: fixed;
	background: rgba(0, 0, 0, 0.6);
	z-index: 7000;

}

.landing-modal {
	position: relative;

	@include hide-scrollbar;
	// overflow-y: scroll;
	height: 94%;
}

.main-frame {
	@include fill-container;

	display: grid;
	@media screen and (max-width: 960px) {
		display: unset;
	}
}

.main-frame-right {
	@include fill-container;

	position: relative;
	grid-column: 2 / span 1;

	display: grid;
	grid-template-rows: 50px 2.2fr 0.72fr;
	gap: 10px 0px;

	@media screen and (max-width: 960px) {
		grid-template-rows: auto 1fr;
		gap: 0px;
	}

	@media (min-aspect-ratio: 2 / 1) {
		grid-template-rows: 50px auto 19%;
	}
}

.main-header-container {
	padding-top: 6px;
	padding-bottom: 6px;
	grid-row: 1 / span 1;
	width: 100%;
	height: fit-content;
	min-height: 48px;

	display: flex;
	align-items: center;
	justify-content: center;

	position: fixed;
	top: 0;
	left: 0;
	@media (max-width: 960px) {
		z-index: 1000;
		// background-color: $tertiary-color;
		background: $background-gradient;
	}
}

.main-header-inner-container {
	width: 60%;
	margin: 0 auto;
	text-align: center;

	@media (max-width: 1240px) and (min-width: 1100px) {
		width: 50%;
	}
	@media (max-width: 1100px) and (min-width: 960px) {
		width: 45%;
	}
}

.main-header {
	@include main-header-text($color: $text-color);
	@include unselectable-text;
	height: fit-content;
	text-align: center;
	vertical-align: middle;
}

.about-button {
	@include flex-center;
	opacity: 0.8;
	position: fixed;
	right: 10px;
	top: 7px;
	background-color: $contrast-color;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	z-index: 6000;
	cursor: pointer;
	@include link-button-text($color: $tertiary-color);

	&:hover {
		opacity: 1;
		color: $text-highlight;
	}
}

.main-date-display {
	@include main-date-text;
	@include basic-card($radius: 10px);
	@include unselectable-text;

	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	transform: translateY(-90%);

	border: 1px $text-highlight solid;

	width: 280px;
	height: fit-content;
	padding: 3px;
	z-index: 600;

	@media screen and (max-width: 1440px) {
		width: 200px;
	}
	@media screen and (max-width: 1080px) {
		width: 120px;
	}
	@media screen and (max-width: 960px) {
		width: 80vw;
		max-width: 600px;
		text-align: right;
		padding-right: 2vw;

		margin: unset;
		border: unset;
		background-color: unset;
	}
}

.nav-button {
	cursor: pointer;
	opacity: 0.6;

	&:hover {
		opacity: 0.8;
	}
}

.backdrop {
	@include fill-container;

	z-index: 1000;
	background-color: black;
	opacity: 0.6;
	position: fixed;
	top: 0;
	left: 0;
}
