@import "../mixin.scss";

.kayyummeter-container {
	@include basic-card(6px);
	height: 74px;
	width: fit-content;
	position: fixed;
	top: 8px;
	right: 60px;
	z-index: 1040;

	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto;

	@media (max-width: 1000px) {
		height: 68px;
	}

	@media (max-width: 960px) {
		top: unset;
		bottom: 8px;
		right: 12px;
		background-color: $dark-background;
	}
}

.kayyum-info-container {
	@include fill-container;
}

.kayyum-info-container {
	@include fill-container;
	padding: 0px 10px 0px 10px;

	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
}

.kayyum-title {
	@include kayyum-title-text;
}

.kayyum-name {
	@include kayyum-name-text;
}

.streak-count {
	@include kayyum-count-text;
}

.streak-text {
	@include kayyum-streak-text;
}

.kelle-container {
	width: 100%;
	height: 74px;
	padding: 2px 8px 2px 0px;

	display: flex;
	justify-content: right;

	@media (max-width: 1000px) {
		height: 68px;
	}
}

.kelle {
	max-height: 100%;
	max-width: 100%;
	transform: scaleX(-1);
	object-fit: cover;
}
