.left-frame-toggle-button {
	cursor: pointer;
	opacity: 0.8;
	z-index: 1200;
	&:hover {
		opacity: 1;
	}

	@media screen and (max-width: 960px) {
		transform: scale(0.8);
	}
}
