@import "../_mixin.scss";

.event-card-container {
	@include flex-center;

	margin: 20px 0px;
	height: calc(100% - 40px);
	width: max-content;
}

.event-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@include default-text($color: $text-color);
	@include basic-card;

	margin: 0px;
	padding: 10px 30px 4px 30px;

	height: 100%;
	width: 40vw;

	@media screen and (max-width: 960px) {
		@include basic-card($radius: 16px);
		width: 90vw;
		max-width: 600px;
		padding: 4px 8px;
	}

	@media (max-height: 500px) {
		max-width: 400px;
	}
}

.event-card-content {
	display: grid;
	grid-template-rows: 50px minmax(2rem, 68%) 20%;

	width: 100%;
	height: calc(100% - 26px);
	overflow: visible;
}

.event-card-header {
	@include fill-container;
	grid-row: 1 / span 1;
	position: relative;
	height: 50px;
	display: block;
}

.event-card-date {
	@include card-date-text($color: #eaf2f3);
	height: 18px;
	width: fit-content;
	margin-left: auto;

	@media (max-width: 1440px) {
		font-size: 16px;
	}
	@media (max-width: 960px) {
		font-size: 14px;
	}

	&:hover {
		color: $text-highlight;
	}
}

.event-card-title {
	@include card-title-text($color: $text-color);
	@include fitty;
	cursor: pointer;

	position: absolute;
	bottom: 0px;
	vertical-align: baseline;

	&:hover {
		color: $contrast-color;
	}
}

.event-image-container {
	@include fill-container;
	@include flex-center;
	align-self: center;
	cursor: pointer;

	grid-row: 2 / span 1;
}

.event-card-image {
	@include fill-container;
	object-fit: cover;

	&.placeholder {
		cursor: default;
		opacity: 0.8;
	}
}

.item-body {
	@include card-body-text($color: $text-color);
	padding-top: 8px;
	font-size: 16px;
	grid-row: 3 / span 1;

	overflow-y: auto;

	@media (max-height: 400px) {
		display: none;
	}

	p {
		@include card-body-text;
	}
}

.more-button {
	@include clear-button;
	@include default-text($color: $text-color);
	font-weight: 400;
	justify-self: center;
	align-self: center;
	padding: 5px 0;

	@media (max-height: 550px) {
		display: none;
	}

	&:hover {
		color: $text-highlight;
	}
}

.hidden {
	display: none;
}
