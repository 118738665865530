@import "../_mixin.scss";

.large-card-container {
	display: none;
	position: absolute;
	left: 50%;
	top: 75px;
	transform: translate(-50%, 0);
	padding: 4px;
	overflow: hidden auto;

	&.show {
		display: unset;
		width: 85vw;
		min-width: 300px;
		max-width: 720px;
		height: 80vh;
		z-index: 1050;
	}
}

.large-card-body {
	@include fill-container;
	@include basic-card($radius: 16px);
	@include hide-scrollbar;
	background: $contrast-color;
	display: grid;
	grid-template-rows: 1fr 6fr 6fr auto;
	grid-template-columns: 100%;

	overflow-y: scroll;
	padding: 15px;
}

.large-card-header {
	@include fill-container;
	position: relative;
	grid-row: 1 / span 1;
	display: flex;
	flex-direction: column;
	padding: 4px 16px;
}

.close-button {
	@include close-button-text;
	cursor: pointer;
	text-align: end;
	position: absolute;
	top: -12px;
	right: -4px;
	opacity: .7;
	z-index: 70000;
	&:hover, &:active  {
		color: $text-highlight;
		opacity: 1;
	}

}

.large-card-date {
	@include card-date-text($color: black);
	font-weight: 300;
}

.large-card-title {
	@include card-title-text($color: $contrast-text-color);
	font-size: 150%;
}

.info-gallery {
	@include fill-container;
	grid-row: 2 / span 1;
	display: flex;

	.swiper-container {
		width: 90%;
		max-height: 100%;
	}
}
.gallery-image {
	max-height: 100%;
	max-width: 100%;
}

.large-card-text-container {
	grid-row: 3 / span 1;
	margin: 16px 24px;
	* {
		@include card-body-text($color: $contrast-text-color);
		text-indent: 5%;
		padding: 8px 0px;
	}
}

.links-box-container {
	grid-row: 4 / span 1;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	word-break: break-all;

}

.links-box {
	min-height: 50px;
	height: fit-content;
	width: 100%;
	margin: 0 auto;
	padding: 8px 0;
	background-color: white;
	border-radius: 5px;
	padding-left: 5%;
	* {
		@include card-links-text;
	}
}

.full-size-image-container {
	@include fill-container;
	@include flex-center;
	align-items: unset;
	position: absolute;
	z-index: 1100;
	background: rgba(0, 0, 0, 0.818);
}

.gallery-image-container {
	@include fill-container;
	@include flex-center;
	object-fit: cover;
	overflow: hidden;
	cursor: pointer;
}
