@import "../../_mixin.scss";

.license-container {
	width: 100%;
	height: 100%;
	background-color: white;
}

.license {
	color: black;
	font-family: $monospace;

	padding: 8px;

	width: 600px;
	height: 100%;


	outline: none;
	border: none;
	resize: none;
}
