@import "./_mixin.scss";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;

	@include default-text($color: $text-color);
}

#root {
	@include flex-center;
	height: 100vh;
	width: 100vw;
}

body {
	@include flex-center;
	background: $background-gradient;
}
